/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:b205e104-7eb5-4217-a43a-fbd6d8016bb6",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_yNnlKyT8b",
    "aws_user_pools_web_client_id": "22c1s45dmlb7j2jsabc2uk0t7a",
    "oauth": {
        "domain": "vouchapp085489a9-085489a9-dev.auth.ap-southeast-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3010/authorise/,https://admin-dev.vouchfor.com/authorise/,https://admin-staging.vouchfor.com/authorise/,https://admin.vouchfor.com/authorise/",
        "redirectSignOut": "http://localhost:3010/sign-in/,http://localhost:3010/oauth/sign-in/,https://admin-dev.vouchfor.com/sign-in/,https://admin-dev.vouchfor.com/oauth/sign-in/,https://admin-staging.vouchfor.com/sign-in/,https://admin-staging.vouchfor.com/oauth/sign-in/,https://admin.vouchfor.com/sign-in/,https://admin.vouchfor.com/oauth/sign-in/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://smw5s4r4brbzlbo7fum4vx3msm.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-rpb6fi6lwbhepb7yrgnx7iy75a",
    "aws_cloud_logic_custom": [
        {
            "name": "vouchpublicapi",
            "endpoint": "https://bshyfw4h5a.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "restapi",
            "endpoint": "https://1m8rimmkcc.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "embedapi",
            "endpoint": "https://90t330usw6.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "applicationapi",
            "endpoint": "https://sqb89hkpil.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "billingapi",
            "endpoint": "https://sx7g6yovjf.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "graphql2",
            "endpoint": "https://6dldxyt7g0.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        }
    ],
    "aws_dynamodb_all_tables_region": "ap-southeast-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "migrations-dev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "Task-dev",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "session-dev",
            "region": "ap-southeast-2"
        }
    ],
    "aws_user_files_s3_bucket": "vouchaccountbrandlogos124904-dev",
    "aws_user_files_s3_bucket_region": "ap-southeast-2",
    "aws_mobile_analytics_app_id": "40ef39b3a66a40c59e70d99c0a32a350",
    "aws_mobile_analytics_app_region": "us-west-2",
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
